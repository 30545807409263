.App {
  color:#333;
  background: #fff;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* 
.App-link {
  color: #61dafb;
} */
.maincontent{
  padding: 0 1.5rem;
  background: #fff;
}
.logoIcon{
  width: 210px;
  height: 45px;
  background: url('./Images/logo.png') no-repeat;
  background-size: 100% 100%;
}
.logosmall{
  width: 100px;
  height: 23px;
  background: url('./Images/logo.png') no-repeat;
  background-size: 100% 100%;
}
.whiteLogoIcon{
  width: 210px;
  height: 45px;
  background: url('./Images/whiteLogo.png') no-repeat;
  background-size: 100% 100%;
}
.ai{
  width: 12rem;
  height: 8.5rem;
  /* background: url('./Images/bg1.gif') no-repeat;
  background-size: 100% 100%; */
}
.aibg2{
  width: 6.17rem;
  height:5rem;
  background: url('./Images/bg2.png') no-repeat;
  background-size: 100% 100%;
}
.RightArrow{
  position: absolute;
  right:-0.15rem;
  top:25%;
  width: 0.23rem;
  height: 0.35rem;
  background: url('./Images/right.png') no-repeat;
  background-size: 100% 100%;
}
.equalImg{
  width: 0.5rem;
  height: 0.3rem;
  margin:2.68rem 0.3rem;
  background: url('./Images/equal.png') no-repeat;
  background-size: 100% 100%;
}
.AiIcon{
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 2.18rem;
  background: url('./Images/ai.png') no-repeat;
  background-size: 100% 100%;
}
.headTitle >span{
  color: #000;
  font-size: 16px;
  margin-left: 30px;
  cursor: pointer;
}
.carouselimg{
  width:900px;
  height:585px
}
.carouselleft{
  position: absolute;
  left: -1.85rem;
  top:50%;
  cursor: pointer;
  width: 0.9rem;
  height: 0.9rem;
  background: url('./Images/carouselleft.png') no-repeat;
  background-size: 100% 100%;
}
.carouselright{
  position: absolute;
  right: -1.85rem;
  top:50%;
  cursor: pointer;
  width: 0.9rem;
  height: 0.9rem;
  background: url('./Images/carouselright.png') no-repeat;
  background-size: 100% 100%;
}
.carousel1{
  background: url('./Images/Carousel1.png') no-repeat;
  background-size: 100% 100%;
}
.carousel2{
  background: url('./Images/Carousel2.png') no-repeat;
  background-size: 100% 100%;
}
.carousel3{
  background: url('./Images/Carousel3.png') no-repeat;
  background-size: 100% 100%;
}
.carousel4{
  background: url('./Images/Carousel4.png') no-repeat;
  background-size: 100% 100%;
}
.comprehensive{
  width:7.2rem ;
  height:5.5rem;
  background: url('./Images/comprehensive.png') no-repeat;
  background-size: 100% 100%;
}
.recommended_analysis{
  width:9rem ;
  height:5rem;
  background: url('./Images/recommended_analysis.png') no-repeat;
  background-size: 100% 100%;
}
.Airecommend{
  width:2.8rem ;
  height:5rem;
  background: url('./Images/recommend.png') no-repeat;
  background-size: 100% 100%;
}
.AIHR{
  width:8.3rem ;
  height:5.5rem;
  background: url('./Images/aihr.png') no-repeat;
  background-size: 100% 100%;
}
.AISEE{
  width:8.3rem ;
  height:5.5rem;
  background: url('./Images/aisee.png') no-repeat;
  background-size: 100% 100%;
}
.loginbg{
  width: 50%;
  height: 100%;
  background: url('./Images/loginbg.png') no-repeat;
  background-size: 100% 100%;
}
.xnloginbg{
  width: 50%;
  height: 100%;
  background: url('./Images/xnloginbg.png') no-repeat;
  background-size: 100% 100%;
}
.inputNoborder,.inputNoborder>input{
  border:none;
}
.inputNoborder:hover,.inputNoborder:focus,.inputNoborder>input:hover,.inputNoborder>input:focus{
  border:none;
  box-shadow:none;
}
.registerimg{
  width: 0.3rem;
  height: 0.3rem;
  background: url('./Images/zhuceqiye.png') no-repeat;
  background-size: 100% 100%;
}
.loginimg{
  width: 0.3rem;
  height: 0.3rem;
  background: url('./Images/denglu.png') no-repeat;
  background-size: 100% 100%;
}
.aftersale{
  width: 0.6rem;
  height: 0.6rem;
  background: url('./Images/aftersale.png') no-repeat;
  background-size: 100% 100%;
}
.presale{
  width: 0.6rem;
  height: 0.6rem;
  background: url('./Images/presale.png') no-repeat;
  background-size: 100% 100%;
}
.insale{
  width: 0.6rem;
  height: 0.6rem;
  background: url('./Images/insale.png') no-repeat;
  background-size: 100% 100%;
}
.contactus{
  width: 10rem;
  height: 3rem;
  background: url('./Images/contactus.png') no-repeat;
  background-size: 100% 100%;
}
.Telephone{
  width: 0.33rem;
  height: 0.26rem;
  background: url('./Images/dianhua.png') no-repeat;
  background-size: 100% 100%;
}
.address{
  width: 0.22rem;
  height: 0.26rem;
  background: url('./Images/dizhi.png') no-repeat;
  background-size: 100% 100%;
}
.banner{
  width: 18.94rem;
  height: 6rem;
  background: url('./Images/banner.png') no-repeat;
  background-size: 100% 100%;
}
.mainIcon1{
  width: 80px;
  height: 80px;
  background: url('./Images/mainIcon1.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainIcon2{
  width: 80px;
  height: 80px;
  background: url('./Images/mainIcon2.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainIcon3{
  width: 80px;
  height: 80px;
  background: url('./Images/mainIcon3.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainIcon4{
  width: 80px;
  height: 80px;
  background: url('./Images/mainIcon4.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainBrowser1{
  width: 8rem;
  height: 4.5rem;
  background: url('./Images/Browser1.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainBrowser2{
  width: 8rem;
  height: 4.5rem;
  background: url('./Images/Browser2.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainBrowser3{
  width: 8rem;
  height: 4.5rem;
  background: url('./Images/Browser3.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.mainBrowser4{
  width: 8rem;
  height: 4.5rem;
  background: url('./Images/Browser4.png') no-repeat;
  background-size: 100% 100%;
  margin: '0.2rem auto 0';
}
.qrIcon1{
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.1rem;
  background: url('./Images/xnclass.png') no-repeat;
  background-size: 100% 100%;
}
.qrIcon2{
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.1rem;
  background: url('./Images/xnzp.png') no-repeat;
  background-size: 100% 100%;
}
.footera{
  color: #666;
  cursor: pointer;
}
.footera:hover{
  color: #0c82ff;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width:768px){
 
}
@media screen and (min-width:768px) and (max-width:992px){
 
}
